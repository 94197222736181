<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <div class="p-5">
            <div class="logo">
              <img
                width="200"
                height="50"
                class="TextLogo"
                alt=""
                src="@/assets/images/logo.png"
              />
            </div>
            <div class="mt-4 text-center">
              <div
                class="OutlineElement Ltr BCX0 SCXW193994853"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                    sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              >
                <div
                  class="BCX0 SCXW157595166"
                  id="isPasted"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- title -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >FAQs:</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-3"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- question one -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Can I book an online appointment or a surgery without
                          being a registered patient?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer question one -->
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >You need to register yourself as a patient before
                              booking any online appointment or
                              surgery/treatment.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- question two -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166  mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Why should I put my medical history
                          information?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!--answer question two -->
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >The medical history of the patient is a very
                              important data for any doctor to evaluate the
                              case, the medication prescribed and any danger at
                              the time of a surgery evaluation.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- question three -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Who will view my medical history</span
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >,&nbsp;</span
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >my tests</span
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >&nbsp;&amp;&nbsp;</span
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >analyses?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!--answer question three -->
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="13"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >All your medical documents are treated according
                              to the EU GDPR and will only be viewed by the
                              doctors performing any medical consultation or
                              treatment.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166 "
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px 0px 0px 48px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                          color: rgb(68, 114, 196);
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- question four -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Can I book an online appointment or surgery for my
                          family members?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!--answer question four -->
                  <!-- font-weight: bold; -->
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >You can associate your family members to your
                              profile as sub-users to request online
                              appointments or surgeries/treatments for
                              them.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- question five -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Can I book an online appointment and pay later?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!--answer question five -->
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >You can book your online appointment and pay
                              later. We hold the online appointment with
                              the&nbsp;</span
                            ><span
                              class="
                                NormalTextRun
                                ContextualSpellingAndGrammarErrorV2
                                BCX0
                                SCXW157595166
                              "
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                background-repeat: repeat-x;
                                background-position: left bottom;
                                background-image: var(
                                  --urlContextualSpellingAndGrammarErrorV2,
                                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                                );
                                border-bottom: 1px solid transparent;
                              "
                              >&ldquo; pay</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >&nbsp;later&rdquo; option for 4 hours. In case
                              payment is not carried out within the following 4
                              hours, the appointment is released and can be
                              booked by another patient.&nbsp;</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- question six -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Can I share the medical reports, tests and analysis
                          with the doctor without uploading them at the time of
                          booking?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!--answer question six -->
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >The doctor will look into your medical reports,
                              analysis, tests or any attached document before
                              the time of the consultation to save time during
                              the video-call.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!--answer question six -->
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case the doctor needs to revert back to any of
                              those documents, he can access them through your
                              medical history with him/her.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Sharing the document will jeopardize the
                              appropriate cycle of the evaluation and diagnosis
                              of the doctor.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!--question seveen -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Can I edit or cancel my online appointment?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- answer question seveen -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >
                              <p>from the booking date to one week before your online consultation:</p>
                              the cancellation is free of charge
From 6 days to 72 hours: 50% of the paid amount is refunded
From 72h to consultation date: Non-refundable.&nbsp;</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case you are changing the online appointment
                              to another doctor, please refer to the patient
                              service agent through&nbsp;</span
                            ></span
                          ><a
                            class="Hyperlink BCX0 SCXW157595166"
                            href="mailto:patient.service@doclinia.com"
                            rel="noreferrer noopener"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              cursor: text;
                              text-decoration: none;
                              color: inherit;
                            "
                            target="_blank"
                            ><span
                              class="TextRun Underlined BCX0 SCXW157595166"
                              data-contrast="none"
                              lang="EN-US"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                font-variant-ligatures: none !important;
                                color: rgb(0, 0, 255);
                                font-size: 12pt;
                                text-decoration: underline;
                                line-height: 18px;
                                font-family: 'Times New Roman',
                                  'Times New Roman_EmbeddedFont',
                                  'Times New Roman_MSFontService', serif;
                              "
                              ><span
                                class="NormalTextRun BCX0 SCXW157595166"
                                data-ccp-charstyle="Hyperlink"
                                style="
                                  margin: 0px;
                                  padding: 0px;
                                  user-select: text;
                                  -webkit-user-drag: none;
                                  -webkit-tap-highlight-color: transparent;
                                "
                                >patient.service</span
                              ><span
                                class="NormalTextRun BCX0 SCXW157595166"
                                data-ccp-charstyle="Hyperlink"
                                style="
                                  margin: 0px;
                                  padding: 0px;
                                  user-select: text;
                                  -webkit-user-drag: none;
                                  -webkit-tap-highlight-color: transparent;
                                "
                                >@doclinia.com</span
                              ></span
                            ></a
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="3"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="1"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case you are willing to cancel the online
                              appointment, please check the cancellation
                              conditions already illustrated at the time of
                              booking or&nbsp;</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >revert back</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >&nbsp;to the patient service agent
                              through&nbsp;</span
                            ></span
                          ><a
                            class="Hyperlink BCX0 SCXW157595166"
                            href="mailto:patient.service@doclinia.com"
                            rel="noreferrer noopener"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              cursor: text;
                              text-decoration: none;
                              color: inherit;
                            "
                            target="_blank"
                            ><span
                              class="TextRun Underlined BCX0 SCXW157595166"
                              data-contrast="none"
                              lang="EN-US"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                font-variant-ligatures: none !important;
                                color: rgb(0, 0, 255);
                                font-size: 12pt;
                                text-decoration: underline;
                                line-height: 18px;
                                font-family: 'Times New Roman',
                                  'Times New Roman_EmbeddedFont',
                                  'Times New Roman_MSFontService', serif;
                              "
                              ><span
                                class="NormalTextRun BCX0 SCXW157595166"
                                data-ccp-charstyle="Hyperlink"
                                style="
                                  margin: 0px;
                                  padding: 0px;
                                  user-select: text;
                                  -webkit-user-drag: none;
                                  -webkit-tap-highlight-color: transparent;
                                "
                                >patient.service@doclinia.com</span
                              ></span
                            ></a
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px 0px 0px 48px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- question eight -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >How do I book a follow up online appointment?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer question eight -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="2"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >During your online appointment, your doctor will
                              evaluate the need of a follow up appointment and
                              will inform the approximate date.&nbsp;</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="2"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >The doctor will request from his access an online
                              appointment for you and you will receive a
                              notification on your profile to book the next
                              online appointment with the same doctor</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- answer question eight -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle2 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: circle;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="2"
                        data-aria-posinset="1"
                        data-font="Courier New"
                        data-leveltext="o"
                        data-listid="2"
                        style="
                          margin: 0px 0px 0px 72px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >You can go to &ldquo;repeat online
                              consultation&rdquo;, choose the line of the
                              consumed online appointment and it will take you
                              to the calendar of the same doctor to book the
                              follow-up appointment</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 "
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- question nine -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >How do I book a surgery?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer question nine -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="3"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case you know the type of surgery to be
                              performed, you need to go to hospitals, insert
                              your specialty and surgery type you wish to
                              book.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="3"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case you are not sure about the surgery type
                              you need, please contact one of our&nbsp;</span
                            ><span
                              class="
                                NormalTextRun
                                ContextualSpellingAndGrammarErrorV2
                                BCX0
                                SCXW157595166
                              "
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                background-repeat: repeat-x;
                                background-position: left bottom;
                                background-image: var(
                                  --urlContextualSpellingAndGrammarErrorV2,
                                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                                );
                                border-bottom: 1px solid transparent;
                              "
                              >patients</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >&nbsp;service agents through&nbsp;</span
                            ></span
                          ><a
                            class="Hyperlink BCX0 SCXW157595166"
                            href="mailto:patient.service@doclinia.com"
                            rel="noreferrer noopener"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              cursor: text;
                              text-decoration: none;
                              color: inherit;
                            "
                            target="_blank"
                            ><span
                              class="TextRun Underlined BCX0 SCXW157595166"
                              data-contrast="none"
                              lang="EN-US"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                font-variant-ligatures: none !important;
                                color: rgb(0, 0, 255);
                                font-size: 12pt;
                                text-decoration: underline;
                                line-height: 18px;
                                font-family: 'Times New Roman',
                                  'Times New Roman_EmbeddedFont',
                                  'Times New Roman_MSFontService', serif;
                              "
                              ><span
                                class="NormalTextRun BCX0 SCXW157595166"
                                data-ccp-charstyle="Hyperlink"
                                style="
                                  margin: 0px;
                                  padding: 0px;
                                  user-select: text;
                                  -webkit-user-drag: none;
                                  -webkit-tap-highlight-color: transparent;
                                "
                                >patient.service@doclinia.com</span
                              ></span
                            ></a
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px 0px 0px 48px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"134233279":true,"201341983":0,"335559685":720,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!--  question ten -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >How do I know which is the right surgery in my
                          medical case?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>

                  <!-- answer question ten -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="4"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case you have a local doctor&rsquo;s medical
                              report with the indicated surgery type, you can
                              proceed to book.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="4"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case you are not sure about the right surgery
                              or treatment,&nbsp;</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >contact one of our consultants by&nbsp;</span
                            ><span
                              class="
                                NormalTextRun
                                ContextualSpellingAndGrammarErrorV2
                                BCX0
                                SCXW157595166
                              "
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                background-repeat: repeat-x;
                                background-position: left bottom;
                                background-image: var(
                                  --urlContextualSpellingAndGrammarErrorV2,
                                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                                );
                                border-bottom: 1px solid transparent;
                              "
                              >email :</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >&nbsp;patient.service@doclinia.com</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>

                  <!--  question eleventh -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Why do I pay the processing fee for my surgery and is
                          it refundable?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- answer question eleventh -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="5"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >The processing fee is the fee paid for the
                              organization and coordination with the hospital,
                              doctors, needed translations of your documents as
                              well as the confirmation of the desired
                              date.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="5"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case the surgery is not accepted by the
                              hospital or you wish to cancel the surgery, we
                              refund you the amount paid deducting the paper
                              work and organization fees.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="3"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="5"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >For more details you can consult our patient
                              service&nbsp;</span
                            ><span
                              class="
                                NormalTextRun
                                ContextualSpellingAndGrammarErrorV2
                                BCX0
                                SCXW157595166
                              "
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                background-repeat: repeat-x;
                                background-position: left bottom;
                                background-image: var(
                                  --urlContextualSpellingAndGrammarErrorV2,
                                  url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iNXB4IiBoZWlnaHQ9IjNweCIgdmlld0JveD0iMCAwIDUgMyIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj4KICAgIDwhLS0gR2VuZXJhdG9yOiBTa2V0Y2ggNTUuMiAoNzgxODEpIC0gaHR0cHM6Ly9za2V0Y2hhcHAuY29tIC0tPgogICAgPHRpdGxlPmdyYW1tYXJfZG91YmxlX2xpbmU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iZ3JhbW1hcl9kb3VibGVfbGluZSIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIj4KICAgICAgICA8ZyBpZD0iR3JhbW1hci1UaWxlLUNvcHkiIHN0cm9rZT0iIzMzNTVGRiI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik0wLDAuNSBMNSwwLjUiIGlkPSJMaW5lLTItQ29weS0xMCI+PC9wYXRoPgogICAgICAgICAgICA8cGF0aCBkPSJNMCwyLjUgTDUsMi41IiBpZD0iTGluZS0yLUNvcHktMTEiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==')
                                );
                                border-bottom: 1px solid transparent;
                              "
                              >agents</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >&nbsp;department through
                              patient.service@doclinia.com</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>

                  <!-- question twenty -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >How do I pay the remaining amount of the
                          surgery?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- answer question twenty -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="6"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Depending on the regulations of each country, the
                              patient service agents will inform you the bank
                              accounts of the booked hospital and the payment
                              conditions related to your surgery.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>

                  <!--  question Thirteenth -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Can you book the flight and hotel for me?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>

                  <!-- answer question Thirteenth -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="6"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >We can book the flight and hotel for you. Please
                              contact our travel consultants through
                              travel@doclinia.com</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!--  question fourteenth -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >What do I do to get an urgent visa?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer  question fourteenth -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="6"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Our patient service agent will help you through
                              an official letter from the hospital for the
                              consulate of the country.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="6"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Please check with our consultants all doubts
                              regarding visa issuance.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!--  question fiveteenth -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >How to&nbsp;</span
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >know&nbsp;</span
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >best hospital and destination for my medical
                          case?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- answer question fiveteenth -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="7"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >There is a guide in our filter on the result page
                              with our recommendation on the best destinations
                              or hospitals for your surgery type.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="7"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >You are also guided by the rating of each
                              hospital&nbsp;</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- emty -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>

                  <!-- question sixteenth -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >What should be taken into consideration when booking
                          a surgery or treatment?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 18px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer question sixteenth -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="3"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="8"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >You need to make sure that you have a valid visa
                              for the selected country</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- answer question sixteenth -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="8"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case you need to issue a visa, please
                              calculate one month time for your selected
                              dates.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="8"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case of doubts about visa procedures, please
                              contact our patient service agents by email.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":240}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 18px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- question seveenteenth -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="
                          LineBreakBlob
                          BlobObject
                          DragDrop
                          BCX0
                          SCXW157595166
                        "
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 11pt;
                          line-height: 19.425px;
                          font-family: WordVisiCarriageReturn_MSFontService,
                            Calibri, Calibri_EmbeddedFont, Calibri_MSFontService,
                            sans-serif;
                        "
                        ><span
                          class="BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            white-space: pre !important;
                          "
                          >&nbsp;</span
                        ></span
                      ><span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >How much does the treatment abroad cost if I take a
                          companion with me?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer question seveenteenth -->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="3"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="9"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Each country has its own policy when it comes to
                              companion at the hospital.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="4"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="9"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case you wish to book accommodation and
                              transfer arrangements for your companion, contact
                              our travel&nbsp;</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >consultants:</span
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >&nbsp;travel@doclinia.com</span
                            ></span
                          ><span
                            class="
                              LineBreakBlob
                              BlobObject
                              DragDrop
                              BCX0
                              SCXW157595166
                            "
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: WordVisiCarriageReturn_MSFontService,
                                'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                                white-space: pre !important;
                              "
                              >&nbsp;</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- question eightteenth -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Is it possible to receive an additional discount in
                          Medical procedures?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer question eightteenth -->

                  <div
                    class="ListContainerWrapper SCXW157595166 BCX0"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 SCXW157595166 BCX0"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="10"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >For humanitarian cases, please contact our
                              patient service agents.</span
                            ></span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- question nineteenth -->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="
                          LineBreakBlob
                          BlobObject
                          DragDrop
                          BCX0
                          SCXW157595166
                        "
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 11pt;
                          line-height: 19.425px;
                          font-family: WordVisiCarriageReturn_MSFontService,
                            Calibri, Calibri_EmbeddedFont, Calibri_MSFontService,
                            sans-serif;
                        "
                        ><span
                          class="BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            white-space: pre !important;
                          "
                          >&nbsp;</span
                        ></span
                      ><span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Can I contact the hospital directly?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer question nineteenth -->

                  <div
                    class="ListContainerWrapper SCXW157595166 BCX0"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 SCXW157595166 BCX0"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="10"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >After booking and for any additional information,
                              we recommend you to contact our patients service
                              agents in order to avoid the language and protocol
                              obstacles of the hospitals.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- question twenty -->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="
                          LineBreakBlob
                          BlobObject
                          DragDrop
                          BCX0
                          SCXW157595166
                        "
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 11pt;
                          line-height: 19.425px;
                          font-family: WordVisiCarriageReturn_MSFontService,
                            Calibri, Calibri_EmbeddedFont, Calibri_MSFontService,
                            sans-serif;
                        "
                        ><span
                          class="BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            white-space: pre !important;
                          "
                          >&nbsp;</span
                        ></span
                      ><span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Does the online consultation with my surgeon have
                          additional charges?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>

                  <!-- answer question twenty -->
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="10"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >The booking of a surgery includes an online
                              consultation with your surgeon free of
                              charge</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>

                  <!-- question twenty one-->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >What guarantees do I have?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <!-- answer question twenty one-->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="10"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >The proposal of the hospital is an official
                              document by the hospital for the price of the
                              surgery and possible additional charges in case of
                              complications.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="3"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="10"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >In case of doubts about the above, please contact
                              our patients service agents.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <!-- question twenty two-->

                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >Is the price of the surgery a fixed price?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>

                  <!-- answer question twenty two-->

                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="11"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >All surgery prices are indicative prices.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="11"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >The final prices are sent to you from the
                              hospital after the analysis and study of your case
                              by the specialist.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <!-- question twenty three-->
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166 mt-2"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                        font-weight: bold;
                      "
                    >
                      <span
                        class="TextRun BCX0 SCXW157595166"
                        data-contrast="auto"
                        lang="EN-US"
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-variant-ligatures: none !important;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >How to order&nbsp;</span
                        ><span
                          class="NormalTextRun BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                          "
                          >a package in Doclinia?</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="3"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="12"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Click on the package type</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <div
                    class="ListContainerWrapper BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      position: relative;
                    "
                  >
                    <ul
                      class="BulletListStyle1 BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow: visible;
                        cursor: text;
                        list-style-type: disc;
                        font-family: verdana;
                      "
                    >
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="1"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="12"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Read carefully the details of the included and
                              excluded items</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="2"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="12"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Indicate the desired date and for how many
                              persons</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                      <li
                        aria-setsize="-1"
                        class="OutlineElement Ltr BCX0 SCXW157595166"
                        data-aria-level="1"
                        data-aria-posinset="3"
                        data-font="Symbol"
                        data-leveltext=""
                        data-listid="12"
                        style="
                          margin: 0px 0px 0px 24px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          overflow: visible;
                          cursor: text;
                          clear: both;
                          position: relative;
                          direction: ltr;
                          display: block;
                          font-size: 12pt;
                          font-family: 'Times New Roman',
                            'Times New Roman_MSFontService', serif;
                          vertical-align: baseline;
                        "
                      >
                        <p
                          class="Paragraph BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            overflow-wrap: break-word;
                            font-weight: normal;
                            font-style: normal;
                            vertical-align: baseline;
                            font-kerning: none;
                            background-color: transparent;
                            color: windowtext;
                            text-align: left;
                            text-indent: 0px;
                          "
                        >
                          <span
                            class="TextRun BCX0 SCXW157595166"
                            data-contrast="auto"
                            lang="EN-US"
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-variant-ligatures: none !important;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            ><span
                              class="NormalTextRun BCX0 SCXW157595166"
                              style="
                                margin: 0px;
                                padding: 0px;
                                user-select: text;
                                -webkit-user-drag: none;
                                -webkit-tap-highlight-color: transparent;
                              "
                              >Submit your request through our system and a
                              consultant will contact you for the coordination
                              of your package booking.</span
                            ></span
                          ><span
                            class="EOP BCX0 SCXW157595166"
                            data-ccp-props='{"134233279":true,"201341983":0,"335559739":160,"335559740":259}'
                            style="
                              margin: 0px;
                              padding: 0px;
                              user-select: text;
                              -webkit-user-drag: none;
                              -webkit-tap-highlight-color: transparent;
                              font-size: 12pt;
                              line-height: 19.425px;
                              font-family: 'Times New Roman',
                                'Times New Roman_EmbeddedFont',
                                'Times New Roman_MSFontService', serif;
                            "
                            >&nbsp;</span
                          >
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="
                          LineBreakBlob
                          BlobObject
                          DragDrop
                          BCX0
                          SCXW157595166
                        "
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 11pt;
                          line-height: 19.425px;
                          font-family: WordVisiCarriageReturn_MSFontService,
                            Calibri, Calibri_EmbeddedFont, Calibri_MSFontService,
                            sans-serif;
                        "
                        ><span
                          class="BCX0 SCXW157595166"
                          style="
                            margin: 0px;
                            padding: 0px;
                            user-select: text;
                            -webkit-user-drag: none;
                            -webkit-tap-highlight-color: transparent;
                            white-space: pre !important;
                          "
                          >&nbsp;</span
                        ></span
                      ><span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                  <div
                    class="OutlineElement Ltr BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow: visible;
                      cursor: text;
                      clear: both;
                      position: relative;
                      direction: ltr;
                    "
                  >
                    <p
                      class="Paragraph BCX0 SCXW157595166"
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        overflow-wrap: break-word;
                        font-weight: normal;
                        font-style: normal;
                        vertical-align: baseline;
                        font-kerning: none;
                        background-color: transparent;
                        color: windowtext;
                        text-align: left;
                        text-indent: 0px;
                      "
                    >
                      <span
                        class="EOP BCX0 SCXW157595166"
                        data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                        style="
                          margin: 0px;
                          padding: 0px;
                          user-select: text;
                          -webkit-user-drag: none;
                          -webkit-tap-highlight-color: transparent;
                          font-size: 12pt;
                          line-height: 19.425px;
                          font-family: 'Times New Roman',
                            'Times New Roman_EmbeddedFont',
                            'Times New Roman_MSFontService', serif;
                        "
                        >&nbsp;</span
                      >
                    </p>
                  </div>
                </div>
                <div
                  class="OutlineElement Ltr BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow: visible;
                    cursor: text;
                    clear: both;
                    position: relative;
                    direction: ltr;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <p
                    class="Paragraph BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow-wrap: break-word;
                      font-weight: normal;
                      font-style: normal;
                      vertical-align: baseline;
                      font-kerning: none;
                      background-color: transparent;
                      color: windowtext;
                      text-align: left;
                      text-indent: 0px;
                    "
                  >
                    <span
                      class="EOP BCX0 SCXW157595166"
                      data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        font-size: 12pt;
                        line-height: 19.425px;
                        font-family: 'Times New Roman',
                          'Times New Roman_EmbeddedFont',
                          'Times New Roman_MSFontService', serif;
                      "
                      >&nbsp;</span
                    >
                  </p>
                </div>
                <div
                  class="OutlineElement Ltr BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow: visible;
                    cursor: text;
                    clear: both;
                    position: relative;
                    direction: ltr;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <p
                    class="Paragraph BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow-wrap: break-word;
                      font-weight: normal;
                      font-style: normal;
                      vertical-align: baseline;
                      font-kerning: none;
                      background-color: transparent;
                      color: windowtext;
                      text-align: left;
                      text-indent: 0px;
                    "
                  >
                    <span
                      class="EOP BCX0 SCXW157595166"
                      data-ccp-props='{"201341983":0,"335559739":160,"335559740":240}'
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        font-size: 12pt;
                        line-height: 18px;
                        font-family: 'Times New Roman',
                          'Times New Roman_EmbeddedFont',
                          'Times New Roman_MSFontService', serif;
                      "
                      >&nbsp;</span
                    >
                  </p>
                </div>
                <div
                  class="OutlineElement Ltr BCX0 SCXW157595166"
                  style="
                    margin: 0px;
                    padding: 0px;
                    user-select: text;
                    -webkit-user-drag: none;
                    -webkit-tap-highlight-color: transparent;
                    overflow: visible;
                    cursor: text;
                    clear: both;
                    position: relative;
                    direction: ltr;
                    color: rgb(0, 0, 0);
                    font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                      sans-serif;
                    font-size: 12px;
                    font-style: normal;
                    font-variant-ligatures: normal;
                    font-variant-caps: normal;
                    font-weight: 400;
                    letter-spacing: normal;
                    orphans: 2;
                    text-align: start;
                    text-indent: 0px;
                    text-transform: none;
                    white-space: normal;
                    widows: 2;
                    word-spacing: 0px;
                    -webkit-text-stroke-width: 0px;
                    background-color: rgb(255, 255, 255);
                    text-decoration-thickness: initial;
                    text-decoration-style: initial;
                    text-decoration-color: initial;
                  "
                >
                  <p
                    class="Paragraph BCX0 SCXW157595166"
                    style="
                      margin: 0px;
                      padding: 0px;
                      user-select: text;
                      -webkit-user-drag: none;
                      -webkit-tap-highlight-color: transparent;
                      overflow-wrap: break-word;
                      font-weight: normal;
                      font-style: normal;
                      vertical-align: baseline;
                      font-kerning: none;
                      background-color: transparent;
                      color: windowtext;
                      text-align: left;
                      text-indent: 0px;
                    "
                  >
                    <span
                      class="EOP BCX0 SCXW157595166"
                      data-ccp-props='{"201341983":0,"335559739":160,"335559740":259}'
                      style="
                        margin: 0px;
                        padding: 0px;
                        user-select: text;
                        -webkit-user-drag: none;
                        -webkit-tap-highlight-color: transparent;
                        font-size: 11pt;
                        line-height: 19.425px;
                        font-family: Calibri, Calibri_EmbeddedFont,
                          Calibri_MSFontService, sans-serif;
                      "
                      >&nbsp;</span
                    >
                  </p>
                </div>
              </div>
              <div
                class="OutlineElement Ltr BCX0 SCXW193994853"
                style="
                  margin: 0px;
                  padding: 0px;
                  user-select: text;
                  -webkit-user-drag: none;
                  -webkit-tap-highlight-color: transparent;
                  overflow: visible;
                  cursor: text;
                  clear: both;
                  position: relative;
                  direction: ltr;
                  color: rgb(0, 0, 0);
                  font-family: 'Segoe UI', 'Segoe UI Web', Arial, Verdana,
                    sans-serif;
                  font-size: 12px;
                  font-style: normal;
                  font-variant-ligatures: normal;
                  font-variant-caps: normal;
                  font-weight: 400;
                  letter-spacing: normal;
                  orphans: 2;
                  text-align: start;
                  text-indent: 0px;
                  text-transform: none;
                  white-space: normal;
                  widows: 2;
                  word-spacing: 0px;
                  -webkit-text-stroke-width: 0px;
                  background-color: rgb(255, 255, 255);
                  text-decoration-thickness: initial;
                  text-decoration-style: initial;
                  text-decoration-color: initial;
                "
              ></div>
            </div>

            <div style="text-align: center">
              <vs-button
                style="width: 60%; margin-top: 2rem"
                @click="$router.go(-1)"
                >{{ $t("back") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    Message: {
      type: String,
      default: () => "",
    },
  },
  components: {},
  computed: {},
  created() {},
};
</script>

<style lang="scss">
.succesuss {
  width: 100%;
  margin: auto;
}
.logo {
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  text-align: center;
}
.P-Text {
  color: #004477;
}
</style>


